import { observer } from 'mobx-react'
import './IndexPage.css';
import React, { Component } from "react";
import { Layout, Space, Button } from "antd";
import { userStore } from '../../storage/user/User';
import { Authentication } from '../../services';
const { Content } = Layout;

@observer
export default class IndexPage extends Component {
    render() {
        return (
            <Space direction="vertical" className="button-container" align="center" size="large">
                {!userStore.token && <Button onClick={() => Authentication.oauth()} type="primary" shape="round">
                    Autenticarse
                </Button>}
                
            </Space>
        )
    }
}