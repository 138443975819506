import React, { Component } from "react";
import { PageHeader, Button, Space } from "antd";
import { userStore } from "../../storage/user/User";
import { observer } from "mobx-react";
import { Authentication } from "../../services";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";

@observer
export default class HeaderContent extends Component {
    render() {
        return (
            <PageHeader extra={[
                <Button style={{ visibility: userStore.token ? 'visible' : 'hidden' }} key="1" type="primary" onClick={() => Authentication.logout()}>
                    Cerrar sesión
                </Button>,
            ]} ghost={false} title={<Space style={{ display: 'flex', flexDirection: 'row' }}>
                <img src={'isologo.png'} style={{ flex: 1, width: "25px", height: "25px" }} />
                <Paragraph style={{ marginBottom: 0 }}>
                    FUTIT Developer
                </Paragraph>
            </Space>} subTitle={userStore.user && userStore.user.fullName} />
        )
    }
}