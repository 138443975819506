import React, { Component } from "react";
import { observer } from "mobx-react";
import { AutoComplete, List, Input, Tooltip, Button } from "antd";
import { userStore } from "../../storage/user/User";
import { Jira } from "../../services";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";

export type IssueSelectorMode = "search" | "list";
export type IssueSelectorType = "self" | "project" | "epics";
export type IssueSelectorBehaviour = "available" | "all";
export type Issue = {
  expand: string;
  fields: any;
  id: string;
  key: string;
  self: string;
};
type PropTypes = {
  behaviour?: IssueSelectorBehaviour;
  mode: IssueSelectorMode;
  project?: string;
  type: IssueSelectorType;
  placeholder?: string;
  value: string;
  exceptions?: string[];
  onSelect?: (selectedIssue: Issue) => void;
  onLoad?: (loadedIssues: Issue[]) => void;
  onInputChanged?: (text: string) => void;
  onMultipleSelectionChanged?: (selectedIssues: Issue[]) => void;
  style?: any;
  showDescription?: boolean;
  multiple?: boolean;
  user?: any;
  className?: string;
};

@observer
export default class IssueSelector extends Component<PropTypes> {
  state: {
    filter?: string;
    issues: Issue[];
    loading: boolean;
    selectedIssue: string;
    onSelect: (selectedIssue: Issue) => void;
    multipleSelection: Issue[];
  };

  constructor(props: any) {
    super(props);
    this.state = {
      issues: [] as Issue[],
      loading: false,
      selectedIssue: "",
      onSelect: () => {},
      multipleSelection: [],
    };
  }

  componentDidUpdate = async (prevProps: any, prevState: any) => {
    if (
      prevProps.project !== this.props.project ||
      prevProps.user !== this.props.user
    ) {
      await this.updateEpics();
    }
  };

  componentDidMount = async () => {
    /*
            TODO: Ensure the user store is mounted at this point.
        */

    await this.updateEpics();
  };

  renderSearch = () => {
    const { issues, loading } = this.state;
    const {
      placeholder,
      value,
      exceptions,
      value: filter,
      style,
      className,
    } = this.props;
    const data: Issue[] = issues;

    return (
      <AutoComplete
        style={{ width: "100%", ...style }}
        className={className}
        options={data
          .filter((item) =>
            exceptions && exceptions.length
              ? !exceptions.includes(item.key)
              : true
          )
          .filter((item) =>
            filter
              ? `${item.key} ${item.fields.summary}`
                  .toLowerCase()
                  .includes(filter.toLowerCase())
              : true
          )
          .map((item) => ({
            label: this.renderInformativeText(item),
            value: item.key,
            //text: `${item.key} - ${item.fields.summary}`
          }))}
        value={value}
        onSearch={(filter: string) => {
          if (this.props.onInputChanged) {
            this.props.onInputChanged(filter);
          }
        }}
        onSelect={(key: string) =>
          this.selectListItem(data.find((issue) => issue.key === key))
        }
        allowClear
      >
        <Input.Search
          loading={loading}
          size="large"
          placeholder={placeholder || "XXX-123"}
        />
      </AutoComplete>
    );
  };

  selectListItem = (item: Issue | undefined) => {
    if (!item) return;
    this.setState({ selectedIssue: item.key });
    this.props.onSelect && this.props.onSelect(item);
    if (this.props.multiple) {
      let { multipleSelection } = this.state;
      const alrSelection = multipleSelection.find((i) => i.key === item.key);
      if (alrSelection) {
        multipleSelection = multipleSelection.filter((i) => i.key !== item.key);
      } else {
        multipleSelection.push(item);
      }

      this.setState({
        multipleSelection,
      });

      this.props.onMultipleSelectionChanged &&
        this.props.onMultipleSelectionChanged(multipleSelection);
    }
  };

  renderListItem = (item: any) => {
    const { selectedIssue, multipleSelection } = this.state;
    const { showDescription, multiple } = this.props;
    const isSelected =
      multipleSelection.findIndex((i) => i.key === item.key) !== -1;
    return (
      <List.Item
        onClick={() => this.selectListItem(item)}
        onDoubleClick={() => {
          window.open(
            `https://etendoproject.atlassian.net/browse/${item.key}`,
            "_blank"
          );
        }}
        style={{
          backgroundColor:
            selectedIssue === item.key || isSelected ? "#151515" : undefined,
        }}
        extra={[
          multiple ? (
            <Button
              icon={isSelected ? <CloseCircleOutlined /> : <PlusOutlined />}
              shape="circle"
            />
          ) : undefined,
        ]}
      >
        <List.Item.Meta
          title={item.fields.summary}
          description={showDescription ? item.fields.description : item.key}
        />
      </List.Item>
    );
  };

  renderList = () => {
    let { issues, loading } = this.state;

    return (
      <List
        size="small"
        loading={loading}
        dataSource={this.getFilteredIssues(issues)}
        itemLayout="horizontal"
        renderItem={(item) => this.renderListItem(item)}
        bordered
        className={this.props.className}
        style={{ ...this.props.style, overflowY: "scroll" }}
      />
    );
  };

  renderMode = (mode: IssueSelectorMode) => {
    if (mode === "list") {
      return this.renderList();
    } else if (mode === "search") {
      return this.renderSearch();
    }
  };

  private renderInformativeText(item: Issue) {
    return (
      <Tooltip title={item.fields.summary} style={{ width: "100%" }}>
        <span>{`${item.key} - ${item.fields.summary}`}</span>
      </Tooltip>
    );
  }

  private getFilteredIssues(issues: Issue[]): Issue[] {
    if (!this.props.exceptions) return issues;

    return issues.filter(
      (issue) => !this.props.exceptions?.includes(issue.key)
    );
  }

  async updateEpics() {
    const { type, project, behaviour, onLoad, user } = this.props;
    let response: any;
    this.setState({ loading: true });
    await userStore.hydrate();

    let selectedUser = user ? user : "currentUser()";

    let queryBehaviour =
      behaviour !== "available"
        ? ``
        : " and (status not in (Closed, Resolved, Done))";

    if (selectedUser === "NO-ASSIGNEE") {
      selectedUser = "null";
    }

    if (type === "self") {
      response = await Jira.getResource(
        "search",
        `jql=issuetype not in (Epic) and assignee=${selectedUser}${queryBehaviour}`
      );
    } else if (type === "project") {
      response = await Jira.getResource(
        "search",
        `jql=issuetype not in (Epic) and project='${project}${queryBehaviour}'`
      );
    } else if (type === "epics") {
      response = await Jira.getResource(
        "search",
        `jql=issuetype in (Epic) and project='${project}${queryBehaviour}'`
      );
    }

    let { issues } = response;

    this.setState({ issues, loading: false }, () => onLoad && onLoad(issues));
  }

  render() {
    const { mode } = this.props;
    return this.renderMode(mode);
  }
}
