import hydrate from "./persistence/hydrate"

export default class PersistentEntity {

    persistentKey: string

    constructor(persistentKey: string) {
        this.persistentKey = persistentKey;
    }

    async hydrate() {
        try {
            return await hydrate(this.persistentKey, this);
        } catch (error) {
            console.error('Error while hydrating', error);
        }
    }
}