import { observable, action } from 'mobx';
import { persist } from 'mobx-persist';
import hydrate from '../persistence/hydrate';
import PersistentEntity from '../PersistentEntity';


type JiraUser = { username: string, fullName: string, email: string, accountId: string }
export class User extends PersistentEntity {


    constructor() {
        super("user");
    }


    @persist
    @observable
    token: string = ""

    @persist('object')
    @observable
    user: JiraUser = <JiraUser>{}


    @persist('object')
    @observable
    stateSnapshot: any = {}


    @action
    setToken(token: string) {
        this.token = token;
    }

    @action
    setUser(user: any) {
        this.user = user;
    }

    @action
    updateStateSnapshot(part: string, state: any) {
        let currentSnapshot = this.stateSnapshot
        currentSnapshot[part] = state;
        this.stateSnapshot = { ...currentSnapshot };
    }
}

const userStore = new User();

userStore.hydrate().then(_ => console.log('Hydrated'))

export { userStore };