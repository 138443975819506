import { Button, List } from 'antd';
import React from 'react';
import { Jira } from '../../services';
import moment from 'moment';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import ms from 'ms';
import prettyms from 'pretty-ms'
import { userStore } from '../../storage/user/User';
import { observer } from 'mobx-react';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';



export const FOUND_PROBLEMS_TASK = 'INT-5612';
export const NEXT_DAY_TASK = 'INT-5611';

type PropTypes = {
    date?: Date,
    user?: string,
    selectable?: boolean,
    onSelect?: (item: any) => void,
    onLoad?: (resumee: { worklogResumees: any[], totalTime: string }) => void
}

@observer
export class WorklogResumee extends React.Component<PropTypes> {



    state: any
    constructor(props: any) {
        super(props);
        this.state = {
            worklogResumees: [],
            worklogResumeesInternal: []
        }
    }

    componentDidMount = () => {
        this.loadResumee();
    }

    componentDidUpdate = (prevProps: any, prevState: any) => {
        if (prevProps.date !== this.props.date || prevProps.user !== this.props.user) {
            this.loadResumee();
        }
    }


    loadResumee = async () => {
        try {
            this.setState({ loading: true })
            this.loadResumeeInternals();
            const { date, user } = this.props;

            const formattedDate = moment(date || new Date()).format('YYYY-MM-DD');
            const tomorrow = moment(date || new Date()).add(1, 'day').format('YYYY-MM-DD')

            const { issues } = await Jira.getResource('search', `jql=worklogDate >= ${formattedDate} AND worklogDate < ${tomorrow} AND worklogAuthor=${user || 'currentUser()'} AND issue not in (${NEXT_DAY_TASK}, ${FOUND_PROBLEMS_TASK})`)
            const promWorklogs = issues.map((issue: any) => new Promise(async (resolve, reject) => {
                const dto = await Jira.getResource(`issue/${issue.key}/worklog`)
                const worklogs = dto.worklogs.filter((wl: any) => {
                    return moment(wl.updated).isSameOrAfter(moment(formattedDate)) && moment(wl.updated).isBefore(tomorrow) && wl.author.accountId === userStore.user.accountId
                });
                const totalTime = this.getLoggedTime(worklogs);
                resolve({
                    key: issue.key,
                    summary: issue.fields.summary,
                    worklogs,
                    totalTime
                })
            }));

            const worklogResumees = await Promise.all(promWorklogs);
            const totalTimeMs = worklogResumees.reduce((prev: any, curr: any) => {
                return prev + this.calculateTime(curr.totalTime)
            }, 0);

            const totalTime = prettyms(totalTimeMs as number);

            this.setState({ worklogResumees })
            this.props.onLoad && this.props.onLoad({ worklogResumees, totalTime });
        } catch (error) {
            console.error('Error while loading the worklog resumee:', error);
        } finally {
            this.setState({ loading: false })
        }

    }

    loadResumeeInternals = async () => {
        try {
            this.setState({ loadingInternals: true })
            const { date, user } = this.props;

            const formattedDate = moment(date || new Date()).format('YYYY-MM-DD');

            const { issues } = await Jira.getResource('search', `jql=worklogDate=${formattedDate} AND worklogAuthor=${user || 'currentUser()'} AND issue in (${NEXT_DAY_TASK}, ${FOUND_PROBLEMS_TASK})`)
            const promWorklogs = issues.map((issue: any) => new Promise(async (resolve, reject) => {
                const dto = await Jira.getResource(`issue/${issue.key}/worklog`)
                const worklogs = dto.worklogs.filter((wl: any) => moment(wl.updated).isSameOrAfter(moment(formattedDate)) && wl.author.accountId === userStore.user.accountId);
                const totalTime = this.getLoggedTime(worklogs);
                resolve({
                    key: issue.key,
                    summary: issue.fields.summary,
                    worklogs,
                    totalTime
                })
            }));

            const worklogResumeesInternal = await Promise.all(promWorklogs);
            this.setState({ worklogResumeesInternal })
        } catch (error) {
            console.error('Error while loading the worklog resumee:', error);
        } finally {
            this.setState({ loadingInternals: false })
        }

    }


    calculateTime = (timeSpent: string) => {
        const splitted = timeSpent.split(' ');
        let totalMs = 0

        splitted.forEach(spl => {
            const parsedSplit = ms(spl);
            if (parsedSplit) {
                totalMs += parsedSplit
            }
        })

        return totalMs;

    }

    getLoggedTime = (worklogArr: any[]) => {

        console.log('fetched worklogs: ', worklogArr)

        const timeSpentList = worklogArr.map(i => i.timeSpent);
        const reducedTimeSpent = timeSpentList.reduce((prev: number, curr: string) => prev + (this.calculateTime(curr)), 0)
        return prettyms(reducedTimeSpent);
    }

    onItemSelected = (item: any, index: number, internal: boolean = false) => {

        this.setState({
            [internal ? 'worklogResumees' : 'worklogResumeesInternal']: this
                .state
            [internal ? 'worklogResumees' : 'worklogResumeesInternal']
                .map(
                    (wl: any, idx: number) => ({ ...wl, selected: idx === index })
                )
        })



        this.props.onSelect && this.props.onSelect(item);
    }

    render() {
        return (
            <>
                <Paragraph>
                    Tareas
                </Paragraph>
                <List
                    bordered
                    dataSource={this.state.worklogResumees}
                    loading={this.state.loading}
                    style={{ maxHeight: '300px', overflowY: 'scroll' }}
                    renderItem={(item: any, index) => (
                        <List.Item
                            onClick={() => this.onItemSelected(item, index)}
                            key={`wlr-${item.key}`}
                            extra={[
                                <Button style={{ margin: "0 5px" }} shape="circle">
                                    {item.totalTime}
                                </Button>,
                            ]}
                        >
                            {item.key} - {item.summary}
                        </List.Item>
                    )} />

                <Paragraph>
                    Internos
                </Paragraph>

                <List
                    bordered
                    dataSource={this.state.worklogResumeesInternal}
                    loading={this.state.loadingInternals}
                    style={{ maxHeight: '300px', overflowY: 'scroll' }}
                    renderItem={(item: any, index) => (
                        <List.Item
                            onClick={() => this.onItemSelected(item, index, true)}
                            key={`wlr-${item.key}`}
                            extra={[
                                <Button style={{ margin: "0 5px" }} shape="circle">
                                    {item.totalTime}
                                </Button>,
                            ]}
                        >
                            {item.key} - {item.summary}
                        </List.Item>
                    )} />
            </>
        )
    }
}