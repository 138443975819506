import React, { Component } from "react";
import { Switch, Route, Router as RDRouter } from 'react-router-dom';
import IndexPage from "./index/IndexPage";
import MainLayout from "../components/layout/MainLayout";
import history, { getQueryParameter } from "../services/history";
import { userStore } from "../storage/user/User";
import { Jira } from "../services";
import { observer } from "mobx-react";
import WorklogsPage from "./worklogs/WorklogsPage";
import { SettingsPage } from "./settings/SettingsPage";

@observer
export default class Router extends Component {

    async componentDidMount() {
        const accessToken = getQueryParameter("access");
        const secret = getQueryParameter("secret");
        await userStore.hydrate()

        if (accessToken) {
            userStore.setToken(`${accessToken}:${secret}`);
        }


        if (userStore.token) {
            await this.load();
        } else {
            userStore.setUser({})
            userStore.setToken("")
        }

        if (userStore.token) {

            if (!userStore.user.accountId) {
                const ubm = await Jira.getResource('user/bulk/migration', `username=${userStore.user.username}`)
                if (ubm && ubm.length) {
                    const firstUbmRecord = ubm[0];
                    const acctId = firstUbmRecord.accountId;
                    userStore.setUser({ ...userStore.user, accountId: acctId });
                }
            }

            return history.replace('/worklogs')
        } else {
            return history.replace('/')
        }

    }


    async load() {
        try {
            const user = await Jira.getGadget('currentUser')
            userStore.setUser(user);
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        return (
            <RDRouter history={history}>
                <MainLayout>
                    <Switch>
                        <Route exact path="/" component={IndexPage} />
                        <Route exact path="/worklogs" component={WorklogsPage} />
                        <Route exact path="/settings" component={SettingsPage} />
                    </Switch>
                </MainLayout>
            </RDRouter>
        )
    }
}