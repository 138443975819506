import React, { Component } from "react";
import { observer } from "mobx-react";
import { AutoComplete, List, Input, Tooltip, Button } from "antd";
import { userStore } from "../../storage/user/User";
import { Jira } from "../../services";
import { CloseCircleOutlined, PlusOutlined } from "@ant-design/icons";

export type User = {
    accountId: string,
    accountType: string,
    active: boolean,
    avatarUrls: any,
    displayName: string,
    emailAddress: string,
    locale: string,
    self: string,
    timeZone: string
};

type PropTypes = {
    project: string;
    mode: "search" | "list";
    placeholder?: string;
    value: string;
    exceptions?: string[];
    onSelect?: (selectedUser: User) => void;
    onLoad?: (loadedUsers: User[]) => void;
    onInputChanged?: (text: string) => void;
    onMultipleSelectionChanged?: (selectedUsers: User[]) => void;
    style?: any;
    multiple?: boolean
};

@observer
export default class UserSelector extends Component<PropTypes> {
    state: {
        filter?: string;
        users: User[];
        loading: boolean;
        selectedUser: string;
        onSelect: (selectedUser: User) => void;
        multipleSelection: User[];
    };

    constructor(props: any) {
        super(props);
        this.state = {
            users: [] as User[],
            loading: false,
            selectedUser: "",
            onSelect: () => { },
            multipleSelection: []
        };
    }

    componentDidMount = async () => {
        try {
            this.setState({ loading: true })
            let users = await Jira.getResource(`user/assignable/search`, `project=${this.props.project}&maxResults=200`)
            users.push({ accountId: 'NO-ASSIGNEE', displayName: 'Sin asignar', emailAddress: 'Sin asignar' })
            this.setState({ users })
        } catch (error) {
            console.error(error);
        } finally {
            this.setState({ loading: false })
        }
    }

    renderSearch = () => {
        const { users, loading } = this.state;
        const { placeholder, value, exceptions, value: filter, style } = this.props;
        const data: User[] = users;


        return (
            <AutoComplete
                style={{ width: "100%", ...style }}
                options={data
                    .filter((item) =>
                        exceptions && exceptions.length
                            ? !exceptions.includes(item.accountId)
                            : true
                    )
                    .filter((item) =>
                        filter
                            ? `${item.displayName} ${item.emailAddress}`
                                .toLowerCase()
                                .includes(filter.toLowerCase())
                            : true
                    )
                    .map((item) => ({
                        label: this.renderInformativeText(item),
                        value: item.accountId,
                    }))}
                value={value}
                onSearch={(filter: string) => {
                    if (this.props.onInputChanged) {
                        this.props.onInputChanged(filter);
                    }
                }}
                onSelect={(key: string) =>
                    this.selectListItem(data.find((issue) => issue.accountId === key))
                }
            >
                <Input.Search
                    loading={loading}
                    size="large"
                    placeholder={placeholder || "Nombre Apellido"}
                    allowClear
                />
            </AutoComplete>
        );
    };

    selectListItem = (item: User | undefined) => {
        if (!item) return;
        this.setState({ selectedUser: item.accountId });
        this.props.onSelect && this.props.onSelect(item);






        if (this.props.multiple) {
            let { multipleSelection } = this.state;
            const alrSelection = multipleSelection.find(i => i.accountId = item.accountId);
            if (alrSelection) {
                multipleSelection = multipleSelection.filter(i => i.accountId !== item.accountId);
            } else {
                multipleSelection.push(item);
            }

            this.setState({
                multipleSelection
            })

            this.props.onMultipleSelectionChanged && this.props.onMultipleSelectionChanged(multipleSelection);
        }
    };

    renderListItem = (item: User) => {
        const { selectedUser, multipleSelection } = this.state;
        const { multiple } = this.props;
        const isSelected = multipleSelection.findIndex(i => i.accountId === item.accountId) !== -1;
        return (
            <List.Item
                onClick={() => this.selectListItem(item)}
                style={{
                    backgroundColor: selectedUser === item.accountId || isSelected ? "#F0FFFF" : undefined,
                }}
                extra={
                    [
                        multiple ? <Button icon={isSelected ? <CloseCircleOutlined /> : <PlusOutlined />} shape="circle" /> : undefined
                    ]
                }
            >
                <List.Item.Meta
                    title={item.displayName}
                    description={item.emailAddress}
                />
            </List.Item>
        );
    };

    renderList = () => {
        let { users, loading } = this.state;

        return (
            <List
                size="small"
                loading={loading}
                dataSource={this.getFilteredUsers(users)}
                itemLayout="horizontal"
                renderItem={(item) => this.renderListItem(item)}
                bordered
                style={{ ...this.props.style, overflowY: "scroll" }}
            />
        );
    };

    renderMode = (mode: "list" | "search") => {
        if (mode === "list") {
            return this.renderList();
        } else if (mode === "search") {
            return this.renderSearch();
        }
    };

    private renderInformativeText(item: User) {
        return (
            <Tooltip title={item.emailAddress} style={{ width: "100%" }}>
                <span>{`${item.displayName}`}</span>
            </Tooltip>
        );
    }

    private getFilteredUsers(issues: User[]): User[] {
        if (!this.props.exceptions) return issues;

        return issues.filter(
            (issue) => !this.props.exceptions?.includes(issue.accountId)
        );
    }


    render() {
        const { mode } = this.props;
        return this.renderMode(mode);
    }
}
