import * as Axios from 'axios';
import { redirect } from './history';
import { Jira } from '.';
import { userStore } from '../storage/user/User';

export default class Authentication {
    static BASE_URL: string = "https://developer.futit.cloud/"


    /*
     * Will redirect to the OAuth route.
    */
    static oauth() {
        redirect(`${Authentication.BASE_URL}authentication/connect`);
    }

    static logout() {
        userStore.setUser({});
        userStore.setToken("");
    }
}