import React, { Component } from "react";
import { Select } from "antd";
import { Jira } from "../../services";
import defaultStatuses from "./defaultStatuses.json";
const { Option } = Select;

export type Transition = {
  hasScreen: boolean;
  id: string;
  isAvailable: boolean;
  isConditional: boolean;
  isGlobal: boolean;
  isInitial: boolean;
  name: string;
  to: any;
  description: string;
};

export default class StatusSelector extends Component<{
  isNew?: boolean;
  currentStatusName?: string;
  placeholder?: string;
  issueKey: string;
  onSelected?: (transition: Transition | undefined) => void;
  style?: any;
  className?: string | any;
  onLoad?: (loadedTransitions: Transition[]) => void;
}> {
  state: { transitions: Transition[]; loading: boolean };

  constructor(props: any) {
    super(props);

    this.state = {
      transitions: [],
      loading: false,
    };
  }

  async componentDidMount() {
    this.loadTransitions();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.issueKey !== this.props.issueKey) {
      this.loadTransitions();
    }
  }

  loadTransitions = async () => {
    this.setState({ loading: true });
    const { issueKey, isNew } = this.props;
    if (!isNew) {
      const response = await Jira.getResource(`issue/${issueKey}/transitions`);
      const { transitions } = response;
      const transitionsToLoad = [
        ...transitions,
        { id: "CONTINUE", name: "Mantener estado de la tarea" },
      ];
      this.setState({ transitions: transitionsToLoad, loading: false });
      this.props.onLoad && this.props.onLoad(transitionsToLoad);
      this.onTransitionSelected({
        id: "CONTINUE",
        name: "Mantener estado de la tarea",
      } as Transition);
    } else {
      this.setState({ transitions: defaultStatuses, loading: false });
    }
  };

  renderTransitionsOption = (transition: Transition) => {
    const { currentStatusName } = this.props;

    return (
      <Option value={transition.id}>
        {transition.id === "CONTINUE"
          ? `${transition.name} (${currentStatusName})`
          : currentStatusName !== undefined && currentStatusName !== ""
          ? `${currentStatusName} ➞ ${transition.to.name}`
          : transition.name}
      </Option>
    );
  };

  renderTransitions = () => {
    const { transitions } = this.state;

    if (transitions.length === 0) {
      return;
    }

    return transitions.map((transition) =>
      this.renderTransitionsOption(transition)
    );
  };

  onTransitionSelected = (transition: Transition | undefined) =>
    this.props.onSelected && this.props.onSelected(transition);

  render() {
    const { placeholder, style, className } = this.props;
    const { loading, transitions } = this.state;

    if (transitions.length === 0) {
      return null;
    }
    return (
      <Select
        className={className}
        style={{ width: "100%", ...style }}
        defaultValue={"CONTINUE"}
        loading={loading}
        placeholder={placeholder}
        onChange={(selected) =>
          this.onTransitionSelected(transitions.find((i) => i.id === selected))
        }
      >
        {this.renderTransitions()}
      </Select>
    );
  }
}
