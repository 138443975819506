import React from "react";
import { Button, Layout, Menu } from "antd";
import HeaderContent from "../header/HeaderContent";
import { Link } from "react-router-dom";
import Modal from "antd/lib/modal/Modal";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
const { Header, Content, Sider, Footer } = Layout;
const { Item, SubMenu } = Menu;

class MainLayout extends React.Component {
  state = {
    collapsed: false,
    changelog: false,
  };

  onCollapse = (collapsed: boolean) => this.setState({ collapsed });

  showChangelog = () => {
    this.setState({ changelog: true });
  };

  render() {
    const { children } = this.props;

    return (
      <>
        <Modal
          footer={[
            <Button onClick={() => this.setState({ changelog: false })}>
              Ok
            </Button>,
          ]}
          visible={this.state.changelog}
        >
          <Title>Versión 1.5.1 - 16/07/2021</Title>
          <Paragraph>
            - Las tareas en estado Done ya no se muestran. El campo Epic al seleccionar un issue del proyecto ERP se carga bien.
          </Paragraph>
          
          <Title>Versión 1.5 - 11/03/2021</Title>
          <Paragraph>
            - Ahora el campo de "subir como..." es obligatorio (kind of).
          </Paragraph>

          <Title>Versión 1.4 - 09/03/2021</Title>
          <Paragraph>
            - Posibilidad de colocar el tipo de tarea al crearla.
          </Paragraph>

          <Title>Versión 1.3 - 12/01/2021</Title>
          <Paragraph>- Arreglos generales de diseño</Paragraph>

          <Title>Versión 1.2 - 11/01/2021</Title>
          <Paragraph>- Agregado un cargando en "Crear tarea"</Paragraph>
          <Paragraph>
            - Arreglado un bug en el que el contador de horas se quedaba en 0ms
            cuando una de las tareas no tenía tiempo dedicado.
          </Paragraph>

          <Title>Versión 1.1 - 9/09/2020</Title>
          <Paragraph>- Arreglo parcial de responsive (mobile)</Paragraph>
          <Paragraph>- Fix en redondeo de numeros en el resumen</Paragraph>
          <Paragraph>- Fix general en estilos</Paragraph>

          <Title>Versión 1.0 - 3/09/2020</Title>
          <Paragraph>
            - Agregado "Imputación Organizada" como macro creada por defecto.
          </Paragraph>
          <Paragraph>
            - Agregado botón para cancelar creación de macros.
          </Paragraph>

          <Title>Versión 0.9 - 2/09/2020</Title>
          <Paragraph>
            - Agregada funcionalidad para crear, modificar e insertar macros en
            los worklogs. Pruebala dandole al botón de "Macros" debajo del
            editor.
          </Paragraph>

          <Title>Versión 0.8</Title>
          <Paragraph>
            - Ahora se puede modificar el tiempo imputado en el resumen. Al
            modificar el tiempo, saldrá el botón de "Validar y guardar".
          </Paragraph>
          <Paragraph>
            - Agregado un botón de refresh en la parte superior de cada sección.
          </Paragraph>
          <Paragraph>
            - Aunque sigue siendo experimental, puedes activar la persistencia
            de estado (así no se te borrarán las cosas al cerrar o refrescar la
            herramienta). Para activarlo, ve a Utilidades {">"} Configuración{" "}
            {">"} Activar persistencia de estado.
            <b>
              {" "}
              Si se te buguea la aplicación por utilizarlo, solo desactivalo y
              refresca la pestaña del navegador.
            </b>
          </Paragraph>
          <Paragraph>
            - Fix: Fondo blanco y letra blanca en tareas del proximo dia
          </Paragraph>
          <Paragraph>
            - Fix: Arreglado bug en el que sale que la incidencia no tiene epic
            cuando recién creas la tarea sin especificar un epic
          </Paragraph>
        </Modal>
        <Layout style={{ minHeight: "100vh" }}>
          <Sider
            className="hidden md:block"
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          >
            <Menu defaultSelectedKeys={["1"]} mode="inline">
              <SubMenu
                key="sub1"
                title={<span style={{ color: "white" }}>Utilidades</span>}
              >
                <Item key="1">
                  <Link to="/worklogs">Imputar</Link>
                </Item>
                <Item key="2">
                  <Link to="/settings">Configuración</Link>
                </Item>
              </SubMenu>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background h-auto"
              style={{ padding: 0 }}
            >
              <HeaderContent />
            </Header>
            <Content style={{ margin: "0 16px" }}>{children}</Content>
            <Footer style={{ textAlign: "center" }}>
              Versión 1.5 -{" "}
              <Button onClick={() => this.showChangelog()}>
                Ver changelog
              </Button>
            </Footer>
          </Layout>
        </Layout>
      </>
    );
  }
}

export default MainLayout;
