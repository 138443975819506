import Axios from "axios"
import { userStore } from "../storage/user/User"


type HttpMethod = "GET" | "POST" | "PUT"
export default class Jira {

    static BASE_URL = "https://developer.futit.cloud/"

    static async call(url: string, method: HttpMethod = "GET", params: any = {}) {
        return await Axios({
            method,
            url,
            data: params,
            headers: {
                Authorization: `${userStore.token}`,
                "Content-Type": "application/json"
            }
        })
    }


    static async getGadget(gadgetName: string): Promise<any> {
        return (await this.call(`${this.BASE_URL}data/gadget/${gadgetName}`)).data
    }

    static async getResource(resourceName: string, params: string = "") {
        return (await this.call(`${this.BASE_URL}data/resource?resource=${resourceName}&${params}`)).data
    }

    static async postResource(resourceName: string, data: any, params: string = "") {
        return (await this.call(`${this.BASE_URL}data/resource?resource=${resourceName}&${params}`, "POST", data)).data
    }

    static async putResource(resourceName: string, data: any, params: string = "") {
        return (await this.call(`${this.BASE_URL}data/resource?resource=${resourceName}&${params}`, "PUT", data)).data
    }
}