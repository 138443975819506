import { createBrowserHistory } from "history";
const history = createBrowserHistory();

export const redirect = (url: string) => {
    window.location.href = url;
}

export const getQueryParameter = (name: string) => {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default history;