import React, { Component } from "react";
import { observer } from "mobx-react";
import { AutoComplete, List, Avatar, Input, Tooltip } from "antd";
import { userStore } from "../../storage/user/User";
import { Jira } from "../../services";
import { AxiosResponse } from "axios";

export type Project = {
  avatarUrls: any;
  expand: string;
  id: string;
  isPrivate: boolean;
  key: string;
  name: string;
  projectTypeKey: string;
  properties: any;
  self: string;
  simplified: boolean;
  style: string;
};

type PropTypes = {
  onSelect?: (selectedProject: Project) => void;
  onLoad?: (loadedIssues: Project[]) => void;
  placeholder?: string;
  value?: string;
  exceptions?: string[];
  mode?: "list" | "search";
};

@observer
export default class ProjectSelector extends Component<PropTypes> {
  state: {
    filter?: string;
    projects: Project[];
    loading: boolean;
    selectedProject: string;
  };

  constructor(props: any) {
    super(props);
    this.state = {
      projects: [] as Project[],
      loading: false,
      selectedProject: "",
    };
  }

  componentDidMount = async () => {
    const { onLoad } = this.props;
    /*
        TODO: Ensure the user store is mounted at this point.
        */
    await userStore.hydrate();

    this.setState({ loading: true });
    let projects = await Jira.getResource("project", "");

    this.setState(
      { projects, loading: false },
      () => onLoad && onLoad(projects)
    );
  };

  renderSearch = () => {
    const { projects, loading, filter } = this.state;
    const { placeholder, value, exceptions } = this.props;
    const data: Project[] = projects;

    return (
      <AutoComplete
        options={data
          .filter((item) =>
            exceptions && exceptions.length
              ? !exceptions.includes(item.key)
              : true
          )
          .filter((item) =>
            filter
              ? `${item.key} ${item.name}`
                  .toLowerCase()
                  .includes(filter.toLowerCase())
              : true
          )
          .map((item) => ({
            label: this.renderInformativeText(item),
            value: item.key,
            //text: `${item.key} - ${item.fields.summary}`
          }))}
        style={{ width: "100%" }}
        value={value}
        onChange={(filter) => this.setState({ filter })}
        onSelect={(key: string) =>
          this.selectListItem(data.find((project) => project.key === key))
        }
      >
        <Input.Search
          loading={loading}
          size="large"
          placeholder={placeholder || "XXX"}
        />
      </AutoComplete>
    );
  };

  selectListItem = (item: Project | undefined) => {
    if (!item) return;
    this.setState({ selectedProject: item.key });
    console.log(item);
    this.props.onSelect && this.props.onSelect(item);
  };

  renderListItem = (item: any) => {
    const { selectedProject } = this.state;
    return (
      <List.Item
        onClick={() => this.selectListItem(item)}
        style={{
          backgroundColor: selectedProject === item.key ? "gray" : undefined,
        }}
      >
        <List.Item.Meta
          title={
            <a href={`https://incidencias.atlassian.net/browse/${item.key}`}>
              {item.name}
            </a>
          }
          description={item.key}
        />
      </List.Item>
    );
  };

  renderList = () => {
    let { projects, loading } = this.state;

    return (
      <List
        size="small"
        loading={loading}
        dataSource={this.getFilteredProjects(projects)}
        itemLayout="horizontal"
        renderItem={(item) => this.renderListItem(item)}
        bordered
        style={{ width: 250, height: 300, overflowY: "scroll" }}
      />
    );
  };

  renderMode = (mode: "list" | "search" | undefined) => {
    if (mode === "list") {
      return this.renderList();
    } else if (mode === "search") {
      return this.renderSearch();
    }
  };

  private getFilteredProjects(projects: Project[]): Project[] {
    if (!this.props.exceptions) return projects;

    return projects.filter(
      (project) => !this.props.exceptions?.includes(project.key)
    );
  }

  render() {
    const { mode } = this.props;
    return this.renderMode(mode);
  }

  renderInformativeText(item: Project): any {
    return (
      <Tooltip title={item.name} style={{ width: "100%" }}>
        <span>{`${item.key} - ${item.name}`}</span>
      </Tooltip>
    );
  }
}
