import { Button, Card, Checkbox, Form } from 'antd';
import React from 'react';

export class SettingsPage extends React.Component {

    state: any = { preferences: {} };

    componentDidMount = () => {
        this.loadPreferences();
    }

    componentDidUpdate = (prevProps: any, prevState: any) => {
        if (JSON.stringify(this.state.preferences) !== JSON.stringify(prevState)) {
            localStorage.setItem('preferences', JSON.stringify(this.state.preferences))
        }
    }

    loadPreferences = () => {
        try {
            const preferences = JSON.parse(localStorage.getItem('preferences') || '{}');
            this.setState({ preferences })
        } catch (error) {
            this.setState({ preferences: {} })
        }
    }

    saveChanges = (form: any) => {
        const persistedState = { preferences: { ...form, enabledPersistedState: !!this.state.preferences?.enabledPersistedState } }
        this.setState(persistedState)
    }



    render() {
        return (
            <Card style={{ marginTop: 100 }}>
                <Form onFinish={this.saveChanges} initialValues={this.state.preferences}>
                    <Form.Item name="enabledPersistedState" label="Activar persistencia de estado (EXPERIMENTAL)" labelCol={{ span: 24 }}>
                        <Checkbox onChange={(evt) => this.setState({ preferences: { ...this.state.preferences ? this.state.preferences : {}, enabledPersistedState: evt.target.checked } })} checked={this.state.preferences?.enabledPersistedState} />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit">
                            Guardar
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        )
    }
}